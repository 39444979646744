import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PaymentsIcon from '@mui/icons-material/Payments';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SchoolIcon from '@mui/icons-material/School';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

export const logo = 'https://avatars.githubusercontent.com/u/70349076?s=400&u=78c9f8de0088c8355c368f4b060a8e731fb75054&v=4';

export const categories = [
  { name: 'Novos Cursos', icon: <HomeIcon />, },
  { name: 'AfronetFilmes', icon: <OndemandVideoIcon />, },
  { name: 'Tecmagest PaqueteAcademy', icon: <SchoolIcon />, },
  { name: 'PaqueteEnsina TV', icon: <SchoolIcon />, },
  { name: 'Programação', icon: <CodeIcon />, },
  { name: 'React / Angular', icon: <CodeIcon />, },
  { name: '.NET / C#', icon: <CodeIcon />, },
  { name: 'Java | Python ', icon: <CodeIcon />, },
  { name: 'Educação', icon: <SchoolIcon />, },
  { name: 'Finanças', icon: <PaymentsIcon />, },
  { name: 'Comédia', icon: <TheaterComedyIcon />, },
  { name: 'Live', icon: <LiveTvIcon />, },
  { name: 'Podcast', icon: <GraphicEqIcon />, },
  { name: 'Crypto', icon: <DeveloperModeIcon />, },
];

export const demoThumbnailUrl = 'https://i.ibb.co/G2L2Gwp/API-Course.png';
export const demoChannelUrl = '/channel/UCBVjMGOIkavEAhyqpxJ73Dw';
export const demoVideoUrl = '/video/aqd9uKbg7Yk';
export const demoChannelTitle = 'Tecmagest PaqueteAcademy'; 
export const demoVideoTitle = 'Ensino à Distância, de Tecnologias, Segurança Cibernética, Marketing Digital & Línguas | RapidAPI';
export const demoProfilePicture = 'https://avatars.githubusercontent.com/u/70349076?s=400&u=e3901cc43a6b9bc166430dc8515056dd8fa806b8&v=4'